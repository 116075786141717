import React from "react"
// import CloseIcon from "../assets/svg/close.inline.svg"
import { graphql, useStaticQuery } from "gatsby"
import stopPropagation from "../utils/stop-propagation"
import Logo from "./Logo"
import UniversalLink from "./UniversalLink"
import MenuLanguage from "./MenuLanguage"
// import SocialMenu from "./SocialMenu"

const MenuModal = ({
  isActive,
  toggleBackdrop,
  defaultLangKey,
  langKey,
  langsMenu,
  homeLink,
}) => {
  // need to get menu items in both languages because can't use variables in static query
  const { allWpMenuItem } = useStaticQuery(graphql`
    {
      allWpMenuItem(filter: { locations: { in: [EXPANDED, EXPANDED___EN] } }) {
        nodes {
          databaseId
          label
          url
          path
          connectedNode {
            node {
              uri
              ... on WpCategory {
                language {
                  slug
                }
              }
              ... on WpPost {
                language {
                  slug
                }
              }
              ... on WpPage {
                language {
                  slug
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!allWpMenuItem?.nodes || allWpMenuItem.nodes === 0) return null

  return (
    <div
      className={
        "menu-modal cover-modal header-footer-group show-modal" +
        (isActive ? " active" : "")
      }
      data-modal-target-string=".menu-modal"
      onClick={(e) => toggleBackdrop(e, false)}
    >
      <div onClick={stopPropagation} className="menu-modal-inner modal-inner">
        <div className="menu-wrapper section-inner">
          <div className="menu-top">
            <div className="header-inner section-inner">
              <div className="header-titles-wrapper">
                <div className="header-titles">
                  <h1 className="site-title">
                    <Logo homeLink={homeLink} />
                  </h1>
                </div>

                <MenuLanguage langsMenu={langsMenu} />

                <div className="toggle-wrapper nav-toggle-wrapper close-nav-toggle-wrapper has-expanded-menu">
                  <button
                    className="toggle close-nav-toggle fill-children-current-color"
                    data-toggle-target=".menu-modal"
                    data-toggle-body-class="showing-menu-modal"
                    aria-expanded="false"
                    aria-label="Close Menu"
                    data-set-focus=".menu-modal"
                    onClick={(e) => toggleBackdrop(e, false)}
                  >
                    {/* <CloseIcon /> */}
                    <span className="toggle-inner">
                      <span className="toggle-text">Menu</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            {/* <nav
              className="expanded-menu"
              aria-label="Expanded"
              role="navigation"
            >
              <ul className="modal-menu reset-list-style">
                {allWpMenuItem.nodes.map((menuItem, i) => {
                  const path =
                    menuItem?.connectedNode?.node?.uri ?? menuItem.url

                  const itemId = "modal-menu-item-" + menuItem.databaseId

                  return (
                    <li
                      id={itemId}
                      key={itemId}
                      className={
                        "menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home " +
                        itemId
                      }
                    >
                      <div className="ancestor-wrapper">
                        <UniversalLink
                          to={path}
                          activeClassName={
                            "current-menu-item current_page_item"
                          }
                        >
                          {menuItem.label}
                        </UniversalLink>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </nav> */}

            <nav
              className="mobile-menu expanded-menu"
              aria-label="Menu"
              role="navigation"
            >
              <ul className="modal-menu reset-list-style">
                {allWpMenuItem.nodes.map((menuItem, i) => {
                  const path =
                    menuItem?.connectedNode?.node?.uri ?? menuItem.url
                  const itemId = "modal-mobile-menu-item-" + menuItem.databaseId
                  const itemLang =
                    menuItem?.connectedNode?.node?.language?.slug ??
                    defaultLangKey

                  // skip items of other language
                  if (itemLang != langKey) return null

                  return (
                    <li
                      id={itemId}
                      key={itemId}
                      className={
                        "menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home " +
                        itemId
                      }
                    >
                      <div className="ancestor-wrapper">
                        <UniversalLink
                          to={path}
                          activeClassName={
                            "current-menu-item current_page_item"
                          }
                          onClick={(e) =>
                            setTimeout(() => {
                              // close menu
                              toggleBackdrop(e, false)
                            }, 100)
                          }
                        >
                          {menuItem.label}
                        </UniversalLink>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>

          {/* <div className="menu-bottom">
            <SocialMenu isExpanded />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default MenuModal
