import React from "react"
import UniversalLink from "./UniversalLink"
import LogoIcon from "../assets/svg/syjmb.inline.svg"

const Logo = ({ ...props }) => {
  return (
    
    <UniversalLink to={props.homeLink} className="logo">
      <LogoIcon />
    </UniversalLink>

  )
}

export default Logo
