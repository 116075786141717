import React, { useState } from "react"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { graphql, useStaticQuery } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"
import MenuModal from "./MenuModal"

import "@wordpress/block-library/build-style/style.css"
import "../assets/styles/wp-block-library.css"
import "../assets/styles/global.css"
import "../assets/styles/base.css"
import "../assets/styles/base-new.css"
import "../assets/styles/mq.css"
import "../assets/styles/mq-new.css"
import "../assets/styles/print.css"

const backdropClasses = " backdrop"

const Layout = ({ children, location, language, translations, bodyClass }) => {
  // menu / backdrop handling
  const [backdropActive, setBackdropActive] = useState(false)
  const toggleBackdrop = (e, active) => {
    e.preventDefault()
    setBackdropActive(active)
  }

  // language query
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        languages {
          slug
        }
        defaultLanguage {
          slug
        }
      }
    }
  `)
  // console.log(site)

  // language handling
  const url = location.pathname
  const langs = Array.from(wp.languages, (language) => language.slug)
  const defaultLangKey = wp.defaultLanguage.slug
  const langKey =
    language?.slug || getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
  // get menu with language links
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(
    (item) => ({
      ...item,
      translated: false,
      link:
        langKey === item.langKey
          ? item.link.replace(`/${defaultLangKey}/`, "/")
          : `/${item.langKey}/`.replace(`/${defaultLangKey}/`, "/"),
    })
  )
  // console.log(langsMenu, translations)
  // iterate over translations to replace link (and 'translated') in langsMenu
  translations?.forEach((transItem) => {
    // find corresponding element in langsMenu and update fields
    const langsMenuItem = langsMenu.find(
      (item) => item.langKey === transItem.language.slug
    )
    langsMenuItem.translated = true
    langsMenuItem.link = transItem.uri
  })

  return (
    <div
      id={"GatsbyBody"}
      className={
        bodyClass +
        " showing-menu-modal showing-modal" +
        (backdropActive ? backdropClasses : "")
      }
    >
      <Header
        isActive={backdropActive}
        toggleBackdrop={toggleBackdrop}
        langsMenu={langsMenu}
        homeLink={homeLink}
      />

      <MenuModal
        isActive={backdropActive}
        toggleBackdrop={toggleBackdrop}
        defaultLangKey={defaultLangKey}
        langKey={langKey}
        langsMenu={langsMenu}
        homeLink={homeLink}
      />

      <main id="site-content" role="main">
        {children}
      </main>

      <Footer />
    </div>
  )
}

export default Layout
