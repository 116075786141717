import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
// import { FormattedMessage } from "react-intl"

const MenuLanguage = (props) => {
  const links = props.langsMenu.map((lang, i) => (
    <li className={lang.selected ? "current-menu-item" : ""} key={lang.langKey}>
      <Link to={lang.link} key={lang.langKey}>
        {lang.langKey.substring(0, 1)}
      </Link>
      {props.langsMenu.length !== i + 1 && <span className="separator">/</span>}
    </li>
  ))

  return (
    <nav
      className="language-menu-wrapper"
      aria-label="Select Language"
      role="navigation"
    >
      <ul className="language-menu reset-list-style">{links}</ul>
    </nav>
  )
}

MenuLanguage.propTypes = {
  langsMenu: PropTypes.array,
}

export default MenuLanguage
